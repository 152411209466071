<template>
    <article class="program-card h-full rounded-[20px] xl:rounded-[30px]">
        <div class="grid grid-cols-12 base-responsive-gap">
            <div class="program-card__img-container col-span-12 relative" :class="{ 'lg:col-span-7': jumbo }">
                <a :href="url" target="_blank" class="block relative lg:h-full base-responsive-rounding">
                    <div
                        class="thumbnail aspect-h-3 aspect-w-5 lg:aspect-h-[none] lg:aspect-w-[none] bg-white"
                        :class="{ 'sm:aspect-h-3 sm:aspect-w-5 ': jumbo }"
                    >
                        <img v-lazy-load :data-src="img" :alt="title" />
                    </div>
                    <EventSeriesBadge v-if="hasSeries" />
                </a>
                <div class="flex absolute bottom-4 right-4">
                    <EventCategoryBadge
                        v-for="(eventType, i) in eventTypes"
                        :key="eventType"
                        :type="eventType"
                        :class="{ 'ml-2': i > 0 }"
                    />
                </div>
            </div>
            <div
                class="col-span-12"
                :class="{
                    'lg:col-span-5 pr-2 pb-4 pl-2 lg:pt-6 lg:pr-4 lg:pl-0': jumbo,
                    'pr-2 pb-4 pl-2': !jumbo,
                }"
            >
                <div class="flex flex-col xl:flex-row xl:items-center">
                    <div class="font-Poppins font-bold sm:text-lg">
                        {{ date }}
                    </div>
                    <div
                        v-if="location"
                        class="font-Poppins sm:text-lg uppercase xl:max-w-[calc(100%-250px)] relative xl:pl-4 xl:ml-4"
                    >
                        <span
                            class="hidden xl:inline-block absolute left-0 top-1 border-l border-black h-[calc(100%-0.5rem)]"
                        ></span>
                        {{ location }}
                    </div>
                </div>
                <div>
                    <div class="flex flex-col-reverse mb-1 lg:mb-2 mt-2">
                        <a :href="url" target="_blank" class="block program-card__title">
                            <h3 class="font-Poppins text-lg sm:text-xl font-semibold line-clamp-3 lg:line-clamp-2">
                                {{ title }}
                            </h3>
                        </a>

                        <div v-if="location" class="program-card__separator-line"></div>
                    </div>
                    <p v-if="description" class="line-clamp-4">{{ description }}</p>
                </div>
                <div class="flex flex-col sm:flex-row lg:flex-col xl:flex-row justify-between">
                    <div class="flex justify-between flex-wrap lg:justify-start mt-3 lg:mt-4 -mx-2">
                        <Button
                            :label="$t('generic.view')"
                            :to="url"
                            target="_blank"
                            class="p-button-secondary p-button-outlined mx-2 my-1"
                        />
                        <Button
                            v-if="ticketUrl"
                            :label="$t('generic.purchase_ticket')"
                            :to="ticketUrl"
                            target="_blank"
                            class="mx-2 my-1"
                        />
                        <Button
                            v-if="registrationUrl"
                            :label="$t('event.register')"
                            :to="registrationUrl"
                            target="_blank"
                            class="mx-2 my-1"
                        />
                    </div>
                </div>
            </div>
        </div>
    </article>
</template>

<script>
import EventCategoryBadge from '~/components/UI/EventCategoryBadge.vue';
import EventSeriesBadge from '~/components/UI/EventSeriesBadge.vue';

export default {
    name: 'FeaturedProgramCard',
    components: {
        EventSeriesBadge,
        EventCategoryBadge,
    },
    props: {
        jumbo: {
            type: Boolean,
            required: false,
            default: false,
        },
        title: {
            type: String,
            required: true,
        },
        description: {
            type: String,
            required: false,
            default: null,
        },
        img: {
            type: String,
            required: true,
        },
        url: {
            type: String,
            required: true,
        },
        ticketUrl: {
            type: String,
            required: false,
            default: null,
        },
        registrationUrl: {
            type: String,
            required: false,
            default: null,
        },
        date: {
            type: String,
            required: true,
        },
        location: {
            type: String,
            required: false,
            default: null,
        },
        eventTypes: {
            type: Array,
            required: true,
        },
        hasSeries: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
};
</script>

<style scoped>
.program-card__title {
    @apply relative mt-2;
}
.program-card__separator-line {
    height: 1px;
    background-color: black;
    @apply w-16 lg:w-full;
}
</style>
