<template>
    <a :href="url" target="_blank" class="grid grid-cols-12 gap-3 mt-3">
        <div class="col-span-5 lg:col-span-6">
            <div class="thumbnail base-responsive-rounding aspect-w-2 aspect-h-1">
                <img :src="image" :alt="title" />
            </div>
        </div>
        <div class="col-span-7 lg:col-span-6 flex flex-col justify-between">
            <span class="font-bold">
                {{ date }}
            </span>
            <span class="capitalize line-clamp-2">{{ title }}</span>
        </div>
    </a>
</template>

<script>
export default {
    name: 'MapPlaceCard',
    props: {
        url: {
            type: String,
            required: true,
        },
        image: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        date: {
            type: String,
            required: true,
        },
    },
};
</script>

<style scoped></style>
