<template>
    <span class="badge" :class="{ [details.bgColor]: !transparent }">
        <span v-if="!iconOnly" class="badge-label" :class="{ [details.bgColor]: !transparent }">
            {{ details.label }}
        </span>
        <span
            class="bg-contain bg-center bg-no-repeat absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
            :style="`width: 22px; height: 22px; background-image: url(${details.icon})`"
        ></span>
    </span>
</template>

<script>
export default {
    name: 'Badge',
    props: {
        type: {
            type: Number, // 1 = cultural, 2 = outdoor, 3 = family, 4 = sport
            required: true,
        },
        iconOnly: {
            type: Boolean,
            required: false,
            default: false,
        },
        transparent: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    computed: {
        details() {
            let bgColor;
            let icon;
            let label;

            switch (this.type) {
                case 1:
                    bgColor = 'bg-purple-400';
                    icon = require('~/assets/images/icons/building.svg');
                    label = 'kulturális';
                    break;
                case 2:
                    bgColor = 'bg-green-400';
                    icon = require('~/assets/images/icons/tree.svg');
                    label = 'szabadtéri';
                    break;
                case 3:
                    bgColor = 'bg-orange-400';
                    icon = require('~/assets/images/icons/smiley.svg');
                    label = 'család és gyerek';
                    break;
                case 4:
                    bgColor = 'bg-blue-200';
                    icon = require('~/assets/images/icons/basketball.svg');
                    label = 'sport, pályák';
                    break;
            }

            return {
                bgColor,
                icon,
                label,
            };
        },
    },
};
</script>

<style scoped>
.badge {
    @apply relative w-10 h-10 rounded-full;
    @media (hover: hover) {
        &:hover {
            .badge-label {
                opacity: 1;
            }
        }
    }
}
.badge-label {
    @apply text-sm uppercase font-semibold whitespace-nowrap;
    @apply absolute left-8 h-full flex items-center -translate-x-full pl-4 pr-8 rounded-full transition-opacity pointer-events-none;
    opacity: 0;
}
</style>
