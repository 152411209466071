<template>
    <div ref="scrollBox" class="swiper-container swiper-container--promo">
        <div ref="swiper" class="swiper swiper--promo bg-theme-dark lg:bg-theme-dark--swiper">
            <div class="swiper-wrapper z-1">
                <div v-for="(item, i) in items" :key="`gallery-image-${i}`" class="swiper-slide flex items-center">
                    <div class="swiper-slide__background">
                        <picture>
                            <source media="(min-width:992px)" :srcset="item.media.image?.lg" />
                            <source media="(min-width:480px)" :srcset="item.media.image?.md" />
                            <img
                                :src="item.media.image?.sm"
                                :alt="item.title"
                                class="w-full h-full object-cover"
                                loading="lazy"
                            />
                        </picture>
                    </div>

                    <div
                        class="container grid grid-cols-12 relative z-1"
                        :class="
                            fullscreen
                                ? 'pt-12 pb-9 sm:pt-12 sm:pb-24 lg:pt-20 lg:pb-28 xl:pt-[12.5rem] xl:pb-[12rem] 2xl:pt-[18rem] 2xl:pb-[16rem]'
                                : 'py-12 sm:py-20 lg:py-28 xl:py-44'
                        "
                    >
                        <div class="col-span-12 lg:col-start-2 lg:col-span-10 text-center">
                            <div
                                class="font-Poppins font-bold text-2xl sm:text-3xl lg:text-5xl xl:text-6xl text-white uppercase mb-8"
                            >
                                {{ item.title }}
                            </div>
                            <div v-if="item.text" class="lead mb-8 lg:mx-24">
                                {{ item.text }}
                            </div>
                            <Button
                                v-if="item.url"
                                :to="item.url"
                                target="_blank"
                                :label="item.cta_text || $t('generic.view')"
                                class="p-button-secondary p-button-outlined"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div
                v-if="items.length >= 2"
                class="carousel-pagination-block container relative mt-4 sm:mt-0 sm:-translate-y-14 grid grid-cols-12 base-responsive-gap z-2"
            >
                <div
                    class="col-span-12 xl:col-span-10 xl:col-start-2 2xl:col-span-12 2xl:col-start-1 flex items-center justify-center"
                >
                    <div ref="prevBtn" class="swiper-button-prev"></div>
                    <span class="gallery-pagination-counter">01</span>
                    <div ref="pagination" class="swiper-pagination"></div>
                    <span class="gallery-pagination-counter">{{ String(items.length).padStart(2, '0') }}</span>
                    <div ref="nextBtn" class="swiper-button-next"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PromoCarousel',
    props: {
        fullscreen: {
            type: Boolean,
            required: false,
            default: false,
        },
        items: {
            type: Array,
            required: true,
        },
    },
    mounted() {
        // todo: vh-ról pixelre állítani h mobilon ne ugráljon
        this.carousel = new this.$swiper(this.$refs.swiper, {
            slidesPerView: 1,
            speed: 500,
            effect: 'fade',
            loop: true,
            simulateTouch: false,
            navigation: {
                nextEl: this.$refs.nextBtn,
                prevEl: this.$refs.prevBtn,
            },
            pagination: {
                el: this.$refs.pagination,
                clickable: true,
            },
        });

        const scene1 = this.$scrollmagic
            .scene({ triggerElement: '.swiper-container--promo' })
            .setTween('.swiper--promo .swiper-slide > .swiper-slide__background', { y: '200px' })
            .on('change', (e) => {
                // console.log(e);
                // console.log('updated the scene');
            });

        // this.$scrollmagic.attachTo(document.querySelector('#scrollbox'));
        this.$scrollmagic.addScene(scene1);
    },
    beforeDestroy() {
        // this.$scrollmagic.destroy(this.scene1);
        this.carousel.destroy();
    },
};
</script>

<style scoped>
.swiper.swiper--promo {
    .swiper-slide {
        @apply sm:rounded-xl lg:rounded-[40px] overflow-hidden;
        height: unset;
        &::after {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5));
            z-index: 0;
        }
    }
    .swiper-slide__background {
        @apply bg-cover bg-center absolute;
        height: calc(100% + 200px);
        width: 100%;
        top: -200px;
    }

    .carousel-pagination-block {
        @media (max-width: 639px) {
            @apply text-black;
        }
    }
}
</style>
